<template>
  <!-- 维修单详情 -->
  <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
    <div class="index">
      <div class="steps">
        <div>
          维修进度：
          <span>{{detailObj.status_text}}</span>
        </div>
        <div>
          维修加急状态：
          <span :style="{color: detailObj.is_urgenth ? 'red' : '#666'}">{{detailObj.is_urgenth ? '特急' : '普通'}}</span>
        </div>
      </div>

      <!-- 派工 -->
      <div v-if="active === 0" class="index-content">
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">订单号：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{detailObj.order_no}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">商户信息：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{sh_data.sh_name}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">联 系 人 ：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">
              {{detailObj.contact}}
              <a style="color: #999; padding-left: 5px;">{{detailObj.mobile}}</a>
            </span>
          </van-col>
        </van-row>

        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">维修员：</span>
          </van-col>
          <van-col span="18">
            <span
              v-if="!woker_data.staff_name"
              class="font-30 value"
              style="color: #aa8350;"
            >客服正在派工，请耐心等待</span>
            <span v-else class="font-30 value">
              {{woker_data.staff_name}}
              <a
                :href="'tel:' + woker_data.mobile"
                style="text-decoration: underline; color: #1989fa; padding-left: 5px;"
              >{{woker_data.mobile}}</a>
            </span>
          </van-col>
        </van-row>
        <van-row class="row" v-if="[3].includes(detailObj.status)">
          <van-col span="6">
            <span class="font-30 label">到达时间：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{status_logs[3].create_time_text}}</span>
          </van-col>
        </van-row>

        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">维修区域：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{ repairAreaName }}</span>
          </van-col>
        </van-row>

        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">维修类型：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{detailObj.type}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">是否返修：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{detailObj.is_old ? '旧问题返修' : '新问题'}}</span>
          </van-col>
        </van-row>

        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">问题描述：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 desc">{{detailObj.description || '无'}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">现场照片：</span>
          </van-col>
          <van-col span="18">
            <div class="pics" v-if="detailObj.images">
              <van-image
                v-for="(pic, i) in detailObj.images.split(',')"
                :key="i"
                class="pic"
                width="4rem"
                height="3rem"
                fit="cover"
                :src="pic"
                @click="imagePreview(detailObj.images.split(','), i)"
              />
            </div>
            <span v-else class="font-30 desc">无</span>
          </van-col>
        </van-row>

        <div class="index-btns">
          <van-button
            v-if="detailObj.status === 3"
            type="primary"
            plain
            size="small"
            round
            @click="onArriveConfirm"
          >确认到场</van-button>
          <!-- <van-button type="warning" plain size="small" round url="tel: 10086">联系客服</van-button> -->
          <van-button type="danger" plain size="small" round @click="isShowCancel = true">取消订单</van-button>
        </div>
      </div>

      <!-- 维修 -->
      <div v-if="active === 1" class="index-content">
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">维 修 员 ：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{detailObj.woker_data.staff_name}}</span>
          </van-col>
        </van-row>
        <van-row class="row" v-if="[3, 4, 41, 5].includes(detailObj.status)">
          <van-col span="6">
            <span class="font-30 label">到达时间：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{status_logs[3].create_time_text}}</span>
          </van-col>
        </van-row>
        <van-row v-if="[41, 5, 6].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30 label">维修方案：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{detailObj.plan}}</span>
          </van-col>
        </van-row>
        <van-row v-if="[41, 5, 6].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30 label">维修费用：</span>
          </van-col>
          <van-col span="18">
            <h3 class="price">{{detailObj.amount}}元</h3>
          </van-col>
        </van-row>
        <van-row v-if="[5, 6].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30 label">确认时间：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{status_logs[5].create_time_text}}</span>
          </van-col>
        </van-row>
        <van-row v-if="[6].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30 label">维修结果：</span>
          </van-col>
          <van-col span="18">
            <div class="pics">
              <van-image
                v-for="(pic, i) in detailObj.result_images.split(',')"
                :key="i"
                class="pic"
                width="4rem"
                height="3rem"
                fit="cover"
                :src="pic"
                @click="imagePreview(detailObj.result_images.split(','), i)"
              />
            </div>
          </van-col>
        </van-row>
        <div class="index-btns">
          <van-button
            v-if="[1, 2, 3, 41].includes(detailObj.status)"
            type="danger"
            plain
            size="small"
            round
            @click="isShowCancel = true"
          >关闭订单</van-button>
          <van-button
            v-if="[4, 41].includes(detailObj.status)"
            type="primary"
            :loading="detailObj.status === 4"
            loading-text="等待方案"
            :disabled="detailObj.status === 4"
            plain
            size="small"
            round
            @click="onConfirmPlan"
          >确认维修</van-button>
          <van-button
            v-if="[5].includes(detailObj.status)"
            type="success"
            loading
            disabled
            loading-text="维修中"
            size="small"
            round
          >确认维修</van-button>
          <van-button
            v-if="[6].includes(detailObj.status)"
            type="success"
            size="small"
            round
            @click="isShowComplete = true"
          >完成订单</van-button>
          <!-- <van-button type="warning" plain size="small" round url="tel: 10086">联系客服</van-button> -->
        </div>
      </div>

      <!-- 已完成 -->
      <div v-if="active === 2" class="index-content">
        <van-row class="row">
          <van-col span="6">
            <span class="font-30">维修进度:</span>
          </van-col>
          <van-col span="18">
            <span class="font-30" value>{{detailObj.status_text}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
             <span class="font-30 label">{{ detailObj.inside_uid ? '报修人' : '商户信息' }}：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">{{sh_data.sh_name}}</span>
          </van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">联 系 人 ：</span>
          </van-col>
          <van-col span="18">
            <span class="font-30 value">
              {{detailObj.contact}}
              <a style="color: #999; padding-left: 5px;">{{detailObj.mobile}}</a>
            </span>
          </van-col>
        </van-row>
         <van-row class="row">
          <van-col span="6">
            <span class="font-30 label">维修员：</span>
          </van-col>
          <van-col span="18">
            <span
              v-if="!woker_data.staff_name"
              class="font-30 value"
              style="color: #aa8350;"
            >客服正在派工，请耐心等待</span>
            <span v-else class="font-30 value">
              {{woker_data.staff_name}}
              <a
                :href="'tel:' + woker_data.mobile"
                style="text-decoration: underline; color: #1989fa; padding-left: 5px;"
              >{{woker_data.mobile}}</a>
            </span>
          </van-col>
        </van-row>
        <van-row v-if="detailObj.remark" class="row">
          <van-col span="6">
            <span class="font-30">取消原因:</span>
          </van-col>
          <van-col span="18">
            <span class="font-30" value>{{detailObj.remark}}</span>
          </van-col>
        </van-row>
        <van-row v-if="[8].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30">取消时间:</span>
          </van-col>
          <van-col span="18">
            <span class="font-30" value>{{status_logs[8].create_time_text}}</span>
          </van-col>
        </van-row>
        <van-row v-if="[9].includes(detailObj.status)" class="row">
          <van-col span="6">
            <span class="font-30">完成时间:</span>
          </van-col>
          <van-col span="18">
            <span class="font-30" value>{{status_logs[9].create_time_text}}</span>
          </van-col>
        </van-row>
        <van-row v-if="detailObj.amount" class="row">
          <van-col span="6">
            <span class="font-30 label">维修费用：</span>
          </van-col>
          <van-col span="18">
            <h3 class="price">{{detailObj.amount}}元</h3>
          </van-col>
        </van-row>
        <van-row v-if="detailObj.result_images" class="row">
          <van-col span="6">
            <span class="font-30 label">维修结果：</span>
          </van-col>
          <van-col span="18">
            <div class="pics">
              <van-image
                v-for="(pic, i) in detailObj.result_images.split(',')"
                :key="i"
                class="pic"
                width="4rem"
                height="3rem"
                fit="cover"
                :src="pic"
                @click="imagePreview(detailObj.result_images.split(','), i)"
              />
            </div>
          </van-col>
        </van-row>

        <div v-if="[7, 8, 9].includes(detailObj.status)" class="back-home-btn">
          <van-button type="success" size="small" plain round block to="/">返回首页</van-button>
        </div>
      </div>

      <!-- 取消订单弹窗 -->
      <van-popup v-model:show="isShowCancel" round close-on-popstate>
        <div class="popup-content">
          <h3>取消订单原因：</h3>
          <van-radio-group v-model="remark" icon-size="14px">
            <van-cell title="维修费用太高，自行维修" clickable>
              <template #right-icon>
                <van-radio name="维修费用太高，自行维修" />
              </template>
            </van-cell>
            <van-cell title="维修方案不认可，暂不维修" clickable>
              <template #right-icon>
                <van-radio name="维修方案不认可，暂不维修" />
              </template>
            </van-cell>
            <van-cell title="其它原因" clickable>
              <template #right-icon>
                <van-radio name="其它原因" />
              </template>
            </van-cell>
          </van-radio-group>
          <div class="popup-btn">
            <van-button type="warning" size="small" plain round @click="isShowCancel = false">返回订单</van-button>
            <van-button type="black" size="small" plain round @click="onCancelOrder">确认取消</van-button>
          </div>
        </div>
      </van-popup>

      <!-- 确认完成弹窗 -->
      <van-popup v-model:show="isShowComplete" round close-on-popstate>
        <div class="popup-content">
          <h3>请评价服务：</h3>
          <van-radio-group v-model="satisfaction" icon-size="14px">
            <van-cell title="非常满意" clickable>
              <template #right-icon>
                <van-radio name="5" />
              </template>
            </van-cell>
            <van-cell title="满意" clickable>
              <template #right-icon>
                <van-radio name="4" />
              </template>
            </van-cell>
            <van-cell title="一般" clickable>
              <template #right-icon>
                <van-radio name="3" />
              </template>
            </van-cell>
            <van-cell title="不满意" clickable>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
            <van-cell title="非常不满意" clickable>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <van-row>
              <van-field
                  style="border-radius: 4px; background-color: #f7f7f7; margin: 5px;"
                  v-if="satisfaction"
                  v-model="satisfaction_remark"
                  rows="4"
                  type="textarea"
                  maxlength="100"
                  placeholder="请输入原因"
                  show-word-limit
              />
            </van-row>
          </van-radio-group>
          <div class="popup-btn">
            <van-button type="warning" size="small" plain round @click="isShowComplete = false">返回订单</van-button>
            <van-button type="black" size="small" plain round @click="onConfirmFinished">确认完成</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>
import {
  getShOrdedrDetailApi,
  postShArriveConfirmApi,
  postShConfirmPlanApi,
  postShConfirmResultApi,
  postShCancelOrderApi,
} from '@/api'
import { ImagePreview } from 'vant'
export default {
  name: 'MaintainDetail',
  data() {
    return {
      isRefresh: false, // 刷新状态
      sh_info: {}, // 商户登录信息
      order_id: 0, // 订单id
      active: 0,
      remark: '维修费用太高，自行维修', // 取消原因
      isShowCancel: false, // 显示取消订单弹窗，
      isShowComplete: false,
      satisfaction: '',
      satisfaction_remark: '',
      detailObj: {}, // 详情数据
      repairAreas: [
        {
          "id" : 1,
          "name" : "商业户内"
        },
        {
          "id" : 2,
          "name" : "商业公区"
        },
        {
          "id" : 3,
          "name" : "办公户内"
        },
        {
          "id" : 4,
          "name" : "办公公区"
        },
      ], // 维修区域
      status_logs: {},
      sh_data: {}, // 商户信息
      woker_data: {}, // 维修员信息
    }
  },
  computed: {
    repairAreaName() {
      return this.repairAreas.find(item => item.id === this.detailObj.area)?.name || '';
    }
  },
  created() {
    if (!window.localStorage.getItem('sh_info')) return
    this.sh_info = JSON.parse(window.localStorage.getItem('sh_info'))
    if (!this.$route.query.order_id) return
    this.order_id = this.$route.query.order_id
    // 获取详情数据
    this.getShOrdedrDetailData()
  },
  methods: {
    getShOrdedrDetailData() {
      getShOrdedrDetailApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        order_id: this.order_id,
      }).then((res) => {
        console.log('详情数据', res)
        this.detailObj = res.data
        this.status_logs = res.data.status_logs
        this.sh_data = res.data.sh_data
        this.woker_data = res.data.woker_data
        this.isShowComplete = false;
        if ([1, 2, 3].includes(res.data.status)) {
          // 派工
          this.active = 0
        } else if ([4, 41, 5, 6].includes(res.data.status)) {
          this.active = 1
        } else {
          this.active = 2
        }
      })
    },
    // 下拉刷新
    onRefresh() {
      this.getShOrdedrDetailData()
      this.isRefresh = false
    },
    // 图片预览
    imagePreview(images, index) {
      ImagePreview({
        images,
        startPosition: index,
      })
    },
    // 取消订单
    onCancelOrder() {
      console.log('取消订单')
      postShCancelOrderApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        order_id: this.order_id,
        remark: this.remark,
      }).then((res) => {
        console.log('取消订单', res)
        this.$toast({
          type: 'success',
          message: '取消成功！',
          onClose: () => {
            this.$router.push('/maintain/list')
          },
        })
      })
    },
    // 确认维修员到场
    onArriveConfirm() {
      postShArriveConfirmApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        order_id: this.order_id,
      }).then((res) => {
        console.log('确认维修员到场', res)
        this.getShOrdedrDetailData()
      })
    },
    // 确认维修方案
    onConfirmPlan() {
      postShConfirmPlanApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        order_id: this.order_id,
      }).then((res) => {
        console.log('确认维修方案', res)
        this.getShOrdedrDetailData()
      })
    },
    // 确认完成维修
    onConfirmFinished() {
      if ((this.satisfaction === '1' || this.satisfaction === '2') && this.satisfaction_remark === ''){
        return this.$toast('请填写不满意的原因！')
      }
      postShConfirmResultApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        order_id: this.order_id,
        satisfaction : this.satisfaction,
        satisfaction_remark : this.satisfaction_remark
      }).then((res) => {
        console.log('确认维修完成', res)
        this.getShOrdedrDetailData()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.index {
  overflow: auto;
  height: 100vh;
  padding: 0 40px;
  background-color: #f7f7f7;

  .index-content {
    padding: 0 20px 40px;
    margin: 20px 0 40px;
    background-color: #fff;
    border-radius: 10px;
    /deep/ .row {
      padding-top: 20px;
      span {
        color: #666;
      }
      .label {
        color: #666;
      }
      .desc {
        color: #999;
        word-break: break-all;
      }
      .pics {
        .pic {
          margin-right: 10px;
        }
      }
      .price {
        color: red;
      }
      .value {
        color: #222;
        display: flex;
        align-items: center;
        a {
          margin-left: 10px;
        }
      }
    }
    .index-btns {
      padding: 80px 0 20px;
      display: flex;
      justify-content: center;
      /deep/ .van-button {
        width: 100px;
        margin: 0 10px;
      }
    }
  }

  .popup-content {
    width: 600px;
    background-color: #fff;
    h3 {
      margin: 0 40px;
      padding: 30px 0;
      border-bottom: 1px solid #eee;
    }
    /deep/ .van-cell {
      padding: 5px 20px;
    }
    .popup-btn {
      display: flex;
      justify-content: center;
      /deep/ .van-button {
        width: 100px;
        margin: 20px 10px;
      }
    }
  }

  .back-home-btn {
    margin: 80px 80px 40px;
  }
}
</style>
